const Content = {
  items: [
    {
      center: false,
      imageSrc: 'loveBox.jpg',
      headLine: 'Коробочка',
      content: 'Любви',
      id: '001',
      link: 'shop/special'
    },
    {
      center: true,
      imageSrc: 'heart.png',
      headLine: [
        ['футболки', '/shop/shirts/'],
        ['худи', '/shop/hoodies/'],
        ['свитшоты', '/shop/sweatshots/'],
        ['наборы', '/special/'],
        ['книги', '/books/'],
        ['интерьер', '/shop/interior/'],
        ['свой дизайн', '/custom/']
      ],
      content: null,
      id: '002',
      link: 'shop/special'
    },
    {
      center: false,
      imageSrc: 'coupleBox.jpg',
      headLine: 'Парные',
      content: 'наборы к 14 февраля',
      id: '003',
      link: 'shop/special'
    }
  ]
};

export default Content;
