const Content = {
  items: [
    {
      name: 'Работы',
      link: '',
      inFooter: true
    },
    // {
    //   name: 'Магазин',
    //   link: 'shop',
    //   inFooter: true
    // },
    // {
    //   name: 'Картины',
    //   link: 'shop/pictures'
    // },
    {
      name: 'Книги',
      link: 'books'
    },
    {
      name: 'Заказать',
      link: 'order'
    },
    {
      name: null,
      link: null
    },
    {
      name: 'Обо мне',
      link: 'about',
      inFooter: true
    }
    // {
    //   name: 'Заказать',
    //   link: 'about#order',
    //   inFooter: true
    // }
  ]
};

export default Content;
